<template>
    <div class="page-product-detail">
        <div class="container">
            <div class="breadcrumb">
                <router-link to="/">Home</router-link>
                <right theme="filled" size="18" fill="#ccc" />
                <router-link to="/equipment/2">Products</router-link>
                <right theme="filled" size="18" fill="#ccc" />
                <span>Home Brewing</span>
            </div>
            <div class="page-container">
                <div class="attribute-box">
                    <!-- <div class="thumb">
                        <img :src="detail.thumb" />
                    </div> -->
                    <div class="text-container">
                        <h2 class="title">{{ detail.name }}</h2>
                        <div class="date">
                            <calendar-dot class="icon" theme="filled" size="12" fill="#999" :strokeWidth="3"/>
                            <span class="text">{{ detail.published_at }}</span>
                            <eyes class="icon" theme="filled" size="16" fill="#999" :strokeWidth="3"/>
                            <span class="text">{{ detail.visits }}</span>
                        </div>
                        <div class="attribute">
                            <span v-if="detail.capacity"><em>Capacity:</em> {{ detail.capacity }}</span>
                            <span v-if="detail.heating_method"><em>Heating Method:</em> {{ detail.heating_method }}</span>
                            <span v-if="detail.warranty"><em>Warranty:</em> {{ detail.warranty }}</span>
                            <span v-if="detail.brand"><em>Brand Name:</em> {{ detail.brand }}</span>
                            <span v-if="detail.power"><em>Power:</em> {{ detail.power }}</span>
                            <span v-if="detail.meterial"><em>Material:</em> {{ detail.meterial }}</span>
                            <span v-if="detail.inner_polishing_precision"><em>Inner Polishing Precision:</em> {{ detail.inner_polishing_precision }}</span>
                            <span v-if="detail.processing_type"><em>Processing Types:</em> {{ detail.processing_type }}</span>
                            <span v-if="detail.voltage"><em>Voltage:</em> {{ detail.voltage }}</span>
                            <span v-if="detail.dimension"><em>Dimension(L*W*H):</em> {{ detail.dimension }}</span>
                            <span v-if="detail.certification"><em>Certification:</em> {{ detail.certification }}</span>
                            <span v-if="detail.condition"><em>Condition:</em> {{ detail.condition }}</span>
                            <span v-if="detail.function"><em>Function:</em> {{ detail.function }}</span>
                            <span v-if="detail.weight"><em>Weight:</em> {{ detail.weight }}</span>
                            <span v-if="detail.processing"><em>Processing:</em> {{ detail.processing }}</span>
                            <span v-if="detail.origin"><em>Place of Origin:</em> {{ detail.origin }}</span>
                            <p v-if="detail.applicable_industries"><em>Applicable Industries:</em> {{ detail.applicable_industries }}</p>
                            <p v-if="detail.after_sale_service"><em>After sale service:</em> {{ detail.after_sale_service }}</p>
                        </div>
                    </div>
                </div>
                <div class="detail-box">
                    <h4 class="detail-tit">Detail</h4>
                    <div class="detail-container">
                        <div v-html="detail.content"></div>
                    </div>
                </div>
            </div>

            <hot-products />
        </div>

    </div>

    <div class="page-bg">
        <img class="bg" src="@/assets/images/product-banner.png" />
    </div>
</template>

<script>
import { Right, CalendarDot, Eyes, } from '@icon-park/vue-next'
import HotProducts from '@/components/layout/HotProducts.vue'

export default {
    name: 'ProductDetail',
    components: {
        Right, CalendarDot, Eyes,
        HotProducts
    },
    data(){
        return {
            detail: {}
        }
    },
    watch: {
        $route(){
            this.getEquipment(this.$route.params.id);
        }
    },
    created(){
        document.oncontextmenu = new Function("event.returnValue=false");
        document.onselectstart = new Function("event.returnValue=false");
    },
    mounted(){
        this.getEquipment(this.$route.params.id);
    },
    unmounted(){
        document.oncontextmenu = new Function("event.returnValue=true");
        document.onselectstart = new Function("event.returnValue=true");
    },
    methods: {
        getEquipment(id){
            this.$api.equipmentShow(id).then(res => {
                this.detail = res.data;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-product-detail{
    z-index: 99;
    position: relative;
    .container{
        width: 1200px;
        margin: 0 auto;
    }
    .breadcrumb{
        padding: 120px 0 10px;
    }

    .page-container{
        width: 100%;
        .attribute-box{
            @extend .flex-column;
            border-radius: $radius-big;
            background-color: $white;
            box-shadow: $shadow-default;
            .thumb{
                width: 100%;
                // height: 520px;
                border-radius: $radius-big $radius-big 0 0;
                overflow: hidden;
                img{
                    width: 100%;
                }
            }
            .text-container{
                @extend .flex-column;
                padding: 20px;
                .title{
                    font-family: 'cusTitle';
                }
                .date{
                    @extend .flex-row;
                    margin-top: 20px;
                    font-size: 14px;
                    color: #999;
                    .text{
                        margin: 0 10px 0 3px;
                        color: $info;
                    }
                    .icon{
                        @extend .flex-column-middle;
                    }
                }
                .attribute{
                    @extend .flex-row;
                    flex-wrap: wrap;
                    line-height: 30px;
                    margin-top: 30px;
                    font-size: 14px;
                    span{
                        width: 50%;
                    }
                    p{
                        width: 100%;
                    }
                    em{
                        font-style: normal;
                        color: $info;
                    }
                }
            }
        }
        .detail-box{
            @extend .flex-column;
            padding: 20px;
            margin: 10px 0 0;
            border-radius: $radius-big;
            background-color: $white;
            box-shadow: $shadow-default;
            .detail-tit{
                margin-bottom: 20px;
                font-family: 'cusTitle';
                font-size: 24px;
                color: $orange;
            }
        }
    }
}
</style>